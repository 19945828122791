import React, { Component } from 'react'
import BlockManager from './BlockManager';

export class MenuSection extends Component {

    constructor(props){
        super(props);
        this.state = { sectionInfo: props.info};
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.sectionInfo !== props.info) {
          return {
            sectionInfo: props.info,
            
          }
        }

        return null;
    }

    componentDidMount () {
    
       
    }
  

    render() {
        return (
            <div>
           
           <div style={{ width: '100%', backgroundColor: 'white',  textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <div className="BlockContainer">
            {this.state.sectionInfo.map((block, i) =>
              <BlockManager key={i} info={block} />
            )
            }
            </div>
            </div>

            </div>
        )
    }
}

export default MenuSection
