import './App.css';
import { Component } from 'react';
import Page from './Page';
import Event from './Event';
import shamilogo from './media/shamilogo.png';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import i18n from "i18next";
import { BoxLoading } from 'react-loadingg';
import Profile from './Profile';

class App extends Component {
  
  intervalID = 0;

  constructor(props){
    super(props);
    
    
    var pageCode = window.location.href.replace(window.location.origin, "");
    pageCode = pageCode.replace("/", "");   
  
    if(pageCode == ''){
      pageCode = 'shami';
    }
    
    this.state = {showPage: false, pageCode: pageCode, isReady: false, showEvent: false, 
      eventCode: '', logged: false, showLogin: false, openLoader: false, showProfile: false };
        
  }

  componentDidMount () {
    
      var sessiontoken = localStorage.getItem('session_token');
     
      if(sessiontoken === null){     
        this.createToken();
        return;
      }else{
        this.checkToken(sessiontoken);
        return;
      }
  }


  

  firstLoad = () => {
    if(this.state.pageCode == ''){
      //show home
    }else{
      if(this.state.pageCode.includes('event/')){
        let eventCode = this.state.pageCode.replace("event/", "");  
        this.setState({ showEvent: true, eventCode: eventCode });
      }else{
        this.setState({ showPage: true });
      }
    }
    this.getProfileInfo();
  }

  getProfileInfo = () => {
    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken
      })
      };
    
      const apiUrl = 'https://api.shami.app/public/v1/profile/info';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      console.log(r);
      this.setState({  logged: r.logged });
      
    }).catch(e => {
        
    });
  }

  checkToken = (token) => {
    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: token
      })
      };
    
      const apiUrl = 'https://api.shami.app/public/v1/hello/check';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      console.log(r);
      this.setState({  isReady: true });
      this.firstLoad();
      
    }).catch(e => {
        localStorage.removeItem('session_token');
        window.location.reload();
    });

  }

  createToken = () => {
   
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
      };
    
      const apiUrl = 'https://api.shami.app/public/v1/hello/client';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      localStorage.setItem('session_token', r.token);
      this.checkToken(r.token);
    }).catch(e => {
      
       //localStorage.removeItem('session_token');
       // window.location.reload();
    });

  }

 
  closeLogin = () => {
    this.setState({ showLogin: false, showRegister: false, showRecoverPassword: false, errorField: false, errorLogin: false, errorMail: false });
  }


  showLogin = () => {
    this.setState({ showLogin: true, showRegister: false, showRecoverPassword: false, errorField: false, errorLogin: false, errorMail: false });
  }

  showRecoverPassword = () => {
    this.setState({ showLogin: false, showRecoverPassword: true, recoverSuccess: false });
  }

  recoverPassword = () => {
    this.setState({ errorField: false, errorLogin: false, errorMail: false });
    if(!(this.state.email.length>3)){
      this.setState({ errorField: true });
      return;
    }
    this.setState({ openLoader: true });

    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        email: this.state.email,
      })
      };
    
      const apiUrl = 'https://api.shami.app/public/v1/profile/recoverpassword';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     if(r.recover == false){
        this.setState({ errorMail: true });
     }else{
      this.setState({ recoverSuccess: true });
     } 
     
     this.setState({ openLoader: false });
    }).catch(e => {
      this.closeLogin();
      this.getProfileInfo();
      this.setState({ openLoader: false });
    });
  }

  closeAnimation = (e) => {
    this.setState({  animationShake: '', animationShake1: '', animationShake2: ''}); 
    clearInterval(this.intervalID);
}

checkboxAction = (e) => {
  var checkBox = e.target.name;
  if(checkBox === "ck1"){
      if(this.state.ck1){
          this.setState({ ck1: false}); 
      }else{
          this.setState({ ck1: true}); 
      }
  }else if(checkBox === "ck2"){
      if(this.state.ck2){
          this.setState({ ck2: false}); 
      }else{
          this.setState({ ck2: true}); 
      }
  }
  
}


  registerNow = () => {
    this.setState({ errorField: false, errorLogin: false, errorMail: false });
    if(!(this.state.email.length>3 && this.state.password.length>5 && this.state.name.length>2 && this.state.surname.length>2)){
      this.setState({ errorField: true });
      return;
    }

    if(!this.state.ck2){
      this.setState({ animationShake2: 'animationShake'}); 
      this.intervalID = setInterval(this.closeAnimation, 300);
      return;
  }

  

  if(!this.state.ck1){
      this.setState({ animationShake1: 'animationShake'}); 
      this.intervalID = setInterval(this.closeAnimation, 300);
      return;
  }
  this.setState({ openLoader: true });
    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        name: this.state.name,
        surname: this.state.surname,
        email: this.state.email,
        password: this.state.password
      })
      };
    
      const apiUrl = 'https://api.shami.app/public/v1/profile/register';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     if(r.register == false){
        this.setState({ errorMail: true });
     }else{
      this.closeLogin();
      this.getProfileInfo();
     } 
     this.setState({ openLoader: false });
      
    }).catch(e => {
      this.closeLogin();
      this.getProfileInfo();
      this.setState({ openLoader: false });
    });
  }


showRegister = () => {
  this.setState({ showLogin: false, showRegister: true,  errorField: false, errorLogin: false, errorMail: false, email: '', password: '', name: '', surname: '' });
}



loginNow = () => {
    this.setState({ errorField: false, errorLogin: false, errorMail: false });
    if(!(this.state.email.length>3 && this.state.password.length>5)){
      this.setState({ errorField: true });
      return;
    }

    this.setState({ openLoader: true });
  
    var sessiontoken = localStorage.getItem('session_token');
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: sessiontoken,
        email: this.state.email,
        password: this.state.password
      })
      };
    
      const apiUrl = 'https://api.shami.app/public/v1/profile/login';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
     if(r.login == false){
      this.setState({ errorLogin: true });
     }else{
      this.closeLogin();
      this.getProfileInfo();
     } 
     this.setState({ openLoader: false });
      
    }).catch(e => {
      this.closeLogin();
      this.getProfileInfo();
      this.setState({ openLoader: false });
    });

}

insertText = (e) => {
  this.setState({ errorField: false, errorLogin: false, errorMail: false });
  var insert = String(e.target.value);   
  var fieldName = e.target.name;  
  this.setState({ [fieldName]: insert });     
}

clickOnMyProfile = () => {
  if(!this.state.logged){
    this.closeLogin();
    this.setState({ showLogin: true,  errorField: false, errorLogin: false, errorMail: false, email: '', password: '' });
  }else{
    if(this.state.showProfile){
      if(this.state.eventCode == ''){
        this.setState({ showProfile: false, showEvent: false, showPage: true });
      }else{
        this.setState({ showProfile: false, showEvent: true, showPage: false });
      }
    }else{
      this.setState({ showProfile: true, showEvent: false, showPage: false });
    }
    
  }
}

  render(){
    
    return (
     
    <div className='App' style={{ backgroundColor: 'rgb(250,250,250)'}}>
     
     <div style={{ top: 0, width: '100%', height: '40px', position: 'sticky',backgroundColor: 'rgba(255,255,255,0.1)', zIndex: 1000 }}>
   <div style={{ width: '100%', top: '39px', height: '0px', backgroundColor: '#F2F1F9', position: 'absolute' }}></div>
  
   
        <div  style={{ display: "flex",position: 'absolute', right: '1px' }}>
       
        
          {!this.state.logged &&
          <div onClick={this.clickOnMyProfile}  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '12px', fontWeight: '600', verticalAlign: 'middle', margin: '7.5px', 
            height: '25px', borderRadius: '8px', color: 'white', backgroundColor: '#007aff', cursor: 'pointer', width: '100px'}}>
          Login</div> }
          {this.state.logged &&
           <div onClick={this.clickOnMyProfile}  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '12px', fontWeight: '600', verticalAlign: 'middle', margin: '7.5px', 
            height: '25px', borderRadius: '8px', color: 'white', backgroundColor: '#007aff', cursor: 'pointer', width: '100px'}}>
          Account</div>}
         
        </div>
  
      

   <div style={{ display: "flex", flexDirection: "row", justifyContent: "left",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
        <div onClick={this.showHome} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '600', verticalAlign: 'center', textAlign: 'center', cursor: 'pointer'}}>
        <img src={shamilogo} height="40px" style={{ verticalAlign: 'center', marginRight: '15px' }} />
          </div>
        </div>
       
   </div>
     
      { (this.state.isReady && this.state.showPage) &&
       <Page code={this.state.pageCode} />
      } 
      { (this.state.isReady && this.state.showEvent) &&
      <Event showLogin={this.showLogin} logged={this.state.logged} code={this.state.eventCode} />
      }

      { (this.state.isReady && this.state.showProfile) &&
      <Profile />
      }
      

      
      <br /><br /><br />

      <Drawer
                    open={this.state.showLogin}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('login.now')} </span></div>
           
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                value={this.state.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t('password')} 
                onChange={this.insertText}
                />
               </div>
               {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            } 
            {this.state.errorLogin &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.login')}</span></div>
            }
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#007aff', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.loginNow}
            >{i18n.t('confirm')}</p>
                    </div>

                    <div onClick={this.showRecoverPassword} style={{ marginTop: '5px', textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '15px'}}><span style={{cursor: 'pointer', color: 'rgb(50,50,50)', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('forgot.password')}</span></div>
   

                   
                    <div onClick={this.showRegister} style={{ marginTop: '10px', textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 14, fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('register.now')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showRegister}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                            <div>
                            <div style={{textAlign: 'center', margin: '15px'}}>
            <span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('register.now')}</span></div>
           
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="name"
                value={this.state.name}
                placeholder={i18n.t('name')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="surname"
                value={this.state.surname}
                placeholder={i18n.t('surname')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                value={this.state.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                />
               </div>

               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t('password')} 
                onChange={this.insertText}
                />
               </div>
            
            
                <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake2} checked={this.state.ck2} onChange={this.checkboxAction} name="ck2" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.shami.app/agreements/terms-and-conditions.pdf">
             {i18n.t('accept.terms')}
                 </a>
                
             </div>
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <div style={{marginLeft: '25px',  height: '20px', borderRadius: '8px', width: '250px', color: 'black', fontSize: '12px', textAlign: 'left'}}>
             <input className={this.state.animationShake1} checked={this.state.ck1} onChange={this.checkboxAction} name="ck1" type="checkbox" /> 
             <a target="_blank" style={{ marginLeft: '5px', fontSize: '10px',  color: 'rgb(160,160,160)', textDecoration: 'none'}} href="https://www.shami.app/agreements/privacy-policy.pdf">
             {i18n.t('accept.privacy')}
                 </a>
                
             </div>
            </div>

            {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            }
            {this.state.errorMail &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.email')}</span></div>
            }
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#007aff', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.registerNow}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.showRecoverPassword}
                        onRequestClose={this.closeLogin}
                        modalElementClass="modalMobileLogin2">
                          
                           

                          { !this.state.recoverSuccess && 
                            <div> <div style={{textAlign: 'center', margin: '15px'}}>

                             
            <div><span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('forgot.password')}</span></div>
           
               <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'rgb(50,50,50)', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(250,250,250)', border: 'none', outlineWidth: '0', fontSize: this.state.componentFontSize, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="email"
                value={this.state.email}
                placeholder={i18n.t('email')} 
                onChange={this.insertText}
                />
               </div>
               </div>
             


            {this.state.errorField &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.field')}</span></div>
            }
            {this.state.errorMail &&
            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <br/><br/>
            <span style={{color: 'red', fontSize: 12, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('error.email.recover')}</span></div>
            }
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#007aff', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.recoverPassword}
            >{i18n.t('confirm')}</p>
                    </div>
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                  }

{ this.state.recoverSuccess && 
                            <div> <div style={{textAlign: 'center', margin: '15px'}}>

                             
            <div><span style={{color: 'rgb(50,50,50)', fontSize: 20, fontWeight: '700', wordWrap: 'break-word'}}>{i18n.t('forgot.password')}</span></div>

            <br/><br/>
            <div><span style={{color: 'rgb(50,50,50)', fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('recover.passoword.success')}</span></div>
            <br/><br/>
              
                    <div onClick={this.closeLogin} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </div>
                  }
                    </Drawer>

                    <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#007aff" />
                    </div>
                    </Drawer>
    </div>
  );
    }
}

export default App;
