import React, { Component } from 'react'
import eventicon from './media/calendaricon.png';

export class MenuList extends Component {

    constructor(props){
        super(props);
        this.state = { menuList: props.info, selectedSection: 0 };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.menuList !== props.info) {
          return {
            menuList: props.info,
          }
        }

        return null;
    }

    componentDidMount () {
    
       
    }
  

    selectedSection = (index) => {
        this.setState({ selectedSection: index });
     
        this.props.select(this.state.menuList[index].id);
        
        
    }
   

    render() {
        return (
            <div>
              
              <div style={{  marginTop: '20px', position: 'relative', backgroundColor: 'rgb(255,255,255)', display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', flexDirection: 'row',scrollBehavior: 'smooth'}}>


                {
           this.state.menuList.map((menu, i) =>
            <div key={i} onClick={() => this.selectedSection(i)} style={{ cursor: 'pointer', marginLeft: '20px', height: '30px',  flexWrap: 'nowrap'}}>
           {this.state.selectedSection == (i) &&
               <div style={{ }}><div style={{ fontWeight: '600', fontSize: '14px' }}>{menu.name}</div>  
               <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgb(50,50,50)'}}>
               </div></div>
           }
           {this.state.selectedSection != (i) &&
                <div style={{ }}><div style={{ fontWeight: '600', color: 'rgb(100,100,100)', fontSize: '14px' }}>{menu.name}</div>  
                <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgba(50,50,50,0)'}}>
                </div></div>
           }
           
       </div>
           )
    }
                
                </div> 

            </div>
        )
    }
}

export default MenuList
