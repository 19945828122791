import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export class BlockAlbum extends Component {

    constructor(props){
        super(props);

        this.state = { blockInfo: props.info};
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.blockInfo !== props.info) {
          return {
            blockInfo: props.info,
            
          }
        }

        return null;
    }

    render() {
       
       

        return (
            <div >
               <Carousel  autoFocus={true} infiniteLoop={true} showThumbs={false} autoPlay={true} showStatus={false} useKeyboardArrows className="presentation-mode">
                    <div>
                    <img style={{ width: '100%', height: '250px', resizeMode: 'contain', objectFit: 'cover'}} 
                src={this.state.blockInfo.content_1}
                alt="content"
                />
                    </div>
                    <div>
                    <img style={{ width: '100%', height: '250px', resizeMode: 'contain', objectFit: 'cover'}} 
                src={this.state.blockInfo.content_2}
                alt="content"
                />
                   
                    </div>
                    <div>
                    <img style={{ width: '100%', height: '250px', resizeMode: 'contain', objectFit: 'cover'}} 
                src={this.state.blockInfo.content_3}
                alt="content"
                />
                   
                    </div>
                    <div>
                    <img style={{ width: '100%', height: '250px', resizeMode: 'contain', objectFit: 'cover'}} 
                src={this.state.blockInfo.content_4}
                alt="content"
                />
                   
                    </div>
                </Carousel>
            </div>
        )
    }
}

export default BlockAlbum
