import React, { Component } from 'react'
import { Player, ControlBar, BigPlayButton } from 'video-react';
import './video-style.css';


export class BlockVideo extends Component {

    intervalID = 0;

    constructor(props){
        super(props);
        var blockInfo = props.info;
        
        var showControls = false;

        if(blockInfo.show_controls == '0'){
            showControls = true;
        }
        
        this.state = { blockInfo: blockInfo, showControls: showControls};
        
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.blockInfo !== props.info) {
          return {
            blockInfo: props.info,
            
          }
        }

        return null;
    }

    componentDidMount() {
        // subscribe state change
        this.intervalID = setInterval(this.playVideo, 2);
    }



    playVideo = () => {
        clearInterval(this.intervalID);
        this.player.play();
    }

  
    render() {
        return (
            <div>
                
                {this.state.blockInfo.player_size === 'small' &&
                <div style={{ display: 'flex', justifyContent: this.state.blockInfo.player_align}}>
                 <Player ref={player => {
            this.player = player;
          }}
                 playsInline fluid={false} width={100} 
                src={this.state.blockInfo.content} loop muted
                >
                    
                     <ControlBar disableCompletely={this.state.showControls}/>
                     <BigPlayButton position="center" />
                </Player>    
                </div>
                }

                {this.state.blockInfo.player_size === 'medium' &&
                <div style={{ display: 'flex', justifyContent: this.state.blockInfo.player_align}}>
                 <Player ref={player => {
            this.player = player;
          }}
                 playsInline fluid={false} width={200} 
                src={this.state.blockInfo.content} loop muted
                >
                     <ControlBar disableCompletely={this.state.showControls}/>
                     <BigPlayButton position="center" />
                </Player>    
                </div>
                }

                {this.state.blockInfo.player_size === 'adapt' &&
                <div style={{ display: 'flex', justifyContent: this.state.blockInfo.player_align}}>
                 <Player ref={player => {
            this.player = player;
          }}
                playsInline fluid={true} 
                src={this.state.blockInfo.content} loop muted
                >
                     <ControlBar disableCompletely={this.state.showControls}/>
                     <BigPlayButton position="center" />
                </Player>    
                </div>
                }
             
                

            </div>
        )
    }
}
export default BlockVideo
