import React, { Component } from 'react'
import Events from './Events';
import MenuList from './MenuList';
import MenuSection from './MenuSection';
import pagenotfound from './media/pagenotfound.png';

export class Page extends Component {

    constructor(props){
        super(props);
        console.log(props.code);
        this.state = { code: props.code, pageInfo: null, selectedSection: 0, eventsInfo: [], pastEventsInfo: [], menu: [], sectionInfo: null, notExists: false };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.code !== props.code) {
          return {
            code: props.code,
            
          }
        }

        return null;
    }

    componentDidMount () {
    
        this.getPageInfo();
    }
  
    getPageInfo = () => {
        var sessiontoken = localStorage.getItem('session_token');

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.code
            })
            };
          
            const apiUrl = 'https://api.shami.app/public/v1/page/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {           
            
            if(r.info == null){
                this.setState({ notExists: true });
            }else{
                this.setState({ pageInfo: r.info, menu: r.menu, eventsInfo: r.events, pastEventsInfo: r.past_events  });
                if(r.events.length == 0 && r.past_events.length == 0 && r.menu.length > 0){
                  this.getMenuSectionInfo(r.menu[0].id);
                }
            }
            
          }).catch(e => {
              this.setState({ notExists: true });
          });
    }

    selectSection = (id_section) => {
        if(id_section == 0){
            this.setState({ selectedSection: id_section });
        }else{
            this.setState({ selectedSection: id_section });
            this.getMenuSectionInfo(id_section);
        }
       
        
    }

    getMenuSectionInfo = (id_section) => {
        var sessiontoken = localStorage.getItem('session_token');

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              section: id_section
            })
            };
          
            const apiUrl = 'https://api.shami.app/public/v1/page/section';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            console.log(r);
            this.setState({ sectionInfo: r.info  });
            
          }).catch(e => {
              //window.location.reload();
          });
    }

    render() {
        return (
            <div>
            
              {this.state.pageInfo != null &&
              <div style={{ backgroundColor: 'white' }}>
                <div   style={{width: '100%', position: 'relative'}}>
                <div style={{width: '100%', height: 510, left: 0, top: 0}}>
                <img src={this.state.pageInfo.cover_image} width="100%" height="530px" style={{ marginTop: '-50px', filter: 'blur(8px)', WebkitFilter: 'blur(8px)', objectFit: 'cover', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px'}} />
                </div>
               
                <div   style={{width: '100%', alignContent: 'left', alignItems: 'left', display: 'flex', flexDirection: 'column', height: '200px', top: '300px', position: 'absolute', backgroundColor: 'rgb(255,255,255)', borderTopRightRadius: '80px', borderTopLeftRadius: '80px' }}>         
                <div  style={{ alignContent: 'center', alignItems: 'center', height: '50px', marginLeft: '50px', marginTop: '80px', display: 'flex'}}>
                <img src={this.state.pageInfo.profile_image} width="50px" height="50px" style={{ objectFit: 'cover', borderRadius: '25px'}} />
                <div style={{ marginLeft: '20px', color: 'rgb(50,50,50)', fontWeight: '800', fontSize: '18px' }}>{this.state.pageInfo.name}</div>  
                </div>  

               <MenuList select={this.selectSection} info={this.state.menu} />

                </div>
                <div style={{width: '100%', height: 300, left: 0, top: 50, position: 'absolute'}}>
                <img src={this.state.pageInfo.cover_image} width="80%" height="300" style={{ maxWidth: '600px', borderRadius: '20px', objectFit: 'cover'}} />
                </div>

                {(this.state.selectedSection == 0 && this.state.eventsInfo.length>0)&&
                <Events past={this.state.pastEventsInfo} info={this.state.eventsInfo} />
                }
                {((this.state.selectedSection != 0 || (this.state.selectedSection == 0 && this.state.eventsInfo.length==0)) && this.state.sectionInfo != null) &&
                <MenuSection info={this.state.sectionInfo} /> }

                </div>
                
              </div>
              }

            
              {this.state.notExists && 
              <div  style={{ width: '100%', marginTop: '20px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh', display: 'flex'}}>
              <img src={pagenotfound} width="40px" height="40px" style={{ objectFit: 'cover', borderRadius: '25px'}} />
              <div style={{ marginLeft: '20px', color: 'rgb(50,50,50)', fontWeight: '600', fontSize: '18px' }}>Page not found</div>  
              </div> 
              }

              

            </div>
        )
    }
}

export default Page
