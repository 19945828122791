import React, { Component } from 'react'

export class BlockText extends Component {

    constructor(props){
        super(props);

        this.state = { blockInfo: props.info};

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.blockInfo !== props.info) {
          return {
            blockInfo: props.info,
            
          }
        }

        return null;
    }

    render() {
        return (
            <div>
                <div style={{  whiteSpace: 'pre-line', margin: '15px', color: this.state.blockInfo.color, fontSize: this.state.blockInfo.size+'px', fontWeight: this.state.blockInfo.weight}}>
                {this.state.blockInfo.content}
                </div>
            </div>
        )
    }
}

export default BlockText
